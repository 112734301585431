@import "~leaflet/dist/leaflet.css";
.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  font-family: Rubik;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

/* https://stackoverflow.com/questions/69307885/unexpected-blue-border-while-typing-text-react-select */
input[type="text"]:focus {
  box-shadow: none;
}

.leaflet-container {
  height: calc(100vh - theme("spacing.48"));
}

.sessionButton {
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: black;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 17px;
}

table {
  border-spacing: 0;
  border: 1px solid #ededed;
}
table tr:last-child td {
  border-bottom: 0;
}
table th,
table td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
}
table th:last-child,
table td:last-child {
  border-right: 0;
}
table tr:nth-child(even) {
  background-color: #fafafa;
}

table th::before {
  position: absolute;
  right: 15px;
  top: 16px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
table th.sort-asc::before {
  border-bottom: 5px solid #22543d;
}
table th.sort-desc::before {
  border-top: 5px solid #22543d;
}

.mapBoxContainer {
  height: calc(100vh - theme("spacing.16"));
}

.animation {
  position: relative;
  animation: example 3s infinite;
}
@keyframes example {
  0%,
  100% {
    left: 0;
  }

  50% {
    left: 90%;
  }
}
